<template>
  <div>
    <div v-for="(item) in data1" :key="item.id" :class="'menu_' + index">
        <div>
            <div @click="active(item)" :class="['index_' + index, (index > 0) ?  (item['data']['index'] === activeIndexs ? 'active' : 'unactive') : '', item['data']['index'] === activeIndexs ? 'active2': '']">{{ item['data']['title'] }}
                <template v-if="index == 0">
                    <i v-if="!item['isActive']" class="before-float el-icon-arrow-right"></i>
                    <i v-else-if="item['isActive']" class="before-float el-icon-arrow-down"></i>
                </template>
            </div>
            <template v-if="item['children']">
                <div v-show="item['isActive']" class="children">
                    <!-- :path="pwd" :parent="data1" :parentIndex="i" -->
                    <t-menu :data="item['children']" :index="index + 1" :root="root" :activeIndex="activeIndexs"></t-menu>
                </div>
            </template>
        </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'TMenu',
data() {
    return {
    }
},
computed: {
    data1() {
        return this.data
    },
    // pwd() {
    //     return this.index > 0 ? this.path + '>>>' + this.parent[this.parentIndex]['data']['title'] : ''
    // },
    activeIndexs() {
        return this.activeIndex
    }
},
methods: {
    active(item) {
        if (item['isActive']) {
            this.$set(item, 'isActive', false)
        } else {
            this.$set(item, 'isActive', true)
        }
        this.getData(item.data)
    },
    getData(item) {
        this.root.getData({
            data: item,
            // path: this.pwd
        })
    }
},
props: {
    activeIndex: {
        type: String,
        default: ''
    },

    // path: {
    //     type: String,
    //     default: ''
    // },
    // parentIndex: {
    //     type: Number,
    //     default: 0
    // },
    // parent: {
    //     type: Array,
    //     require: false,
    //     default () {
    //         return []
    //     }
    // },

    index: {
        type: Number,
        default () {
            return 0;
        }
    },
    data: {
        type: Array,
        default () {
            return [{
                id: null,
                data: {
                    title: '',
                },
                isActive: false,
                children: []
            }];
        }
    },
    root: {
        type: Object,
        require: true,
        default: this
    }
}
}
</script>

<style lang="less" scoped>
.menu_0 + .menu_0 {
    border-top: 1px solid #dcdcdc;
}
.index_0 {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    background-color: #f9f9f9;
    word-wrap: break-word;
    position: relative;

    .before-float {
        position: absolute;
        left: 5px;
        top: 17px;
    }
}
.active {
    background-color: #dcdcdc;
    border-radius: 5px;
    padding: 5px 7px;
}
.active2 {
    color: #c7000b;
}
.unactive {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 5px 7px;
}
.children {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
}
</style>