<template>
  <div class="container">
    <div style="border: 1px solid #f0f0f0; display: flex;">
        <div class="left">
            <t-menu v-if="datas.length > 0" :data="datas" :root="this" :activeIndex="actives" class="t-menu"></t-menu>
        </div>
        <div class="right">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item v-for="item in path" :key="item">{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="chapter-nav-bar">
                <div class="left-btn" @click="beforeChapter"><span class="el-icon-arrow-left"></span></div>
                <div class="content">{{ obj.title }}</div>
                <div class="right-btn" @click="afterChapter"><span class="el-icon-arrow-right"></span></div>
            </div>
            <template v-if="obj.category && obj.category === 'wiki'">
                <div class="html-content" v-html="obj.content"></div>
            </template>
            <template v-if="obj.category && obj.category === 'pdf'"></template>
            <template v-if="obj.category && obj.category === 'video'"></template>
            <div class="bottom-nav-bar" style="width: 100%;">
                <div class="bottom">
                    <div class="left" @click="beforeChapter">Page Up</div>
                    <div class="right" @click="afterChapter">Page Down</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { getMenuById } from '@/api/myLearnApi';
import tmenu from './menu.vue';
export default {
name: 'MyLearnDetails',
components: {
    't-menu':tmenu
},
data() {
    return {
        datas: [], // 从后端获取到的值
        obj: { // 选中的导航栏中的某个对象
            title: '',
            category: ''
        },
        index: null, // 导航栏中被选中对象的id
        details: null,
        menuContext: {}, // 这个map记录一维化的菜单
        menuContext2: [], // 这个数组记录一维化的map的key，通过 index 即可获得前章与后章id，方便导航
        path: '', // 这个是面包屑
        actives: ''
    }
},
mounted() {
   this.getMenuById(this.$route.params.id)
},
methods: {
    active(indexs) {
        this.actives = indexs;
        let indexArr = indexs.split('-');
        let index = 0;
        let length = indexArr.length;
        let obj = this.datas;
        this.path = [];
        for (let item of indexArr) {
            obj = this.calculate(item, index, length, obj)
            this.$set(obj, 'isActive', true)
            this.path.push(obj.data.title)
            index++;
        }
    },
    calculate(item, index, length, object) {
        if (index === 0) {
            return object[item]
        }
        if (index > 0 && index <= (length - 1)) {
            return object['children'][item]
        }
    },
    async getMenuById(id) {
        const {data: {data, isSuccess}} = await getMenuById({id})
        if (isSuccess) {
            this.datas = data;
            let array = [];
            let index = 0;
            for (let item of data) {
                this.dimensionality(item, array, `${index}`)
                index++;
            }
            // console.log(this.datas)
            let oneDimensionalArray = {}
            for (let item of array) {
                oneDimensionalArray[`${item.id}`] = item;
            }
            this.menuContext = oneDimensionalArray;
            this.menuContext2 = Object.keys(this.menuContext);

            this.getData({
                data: this.datas[0].data
            })
            // console.log(oneDimensionalArray)
        }
    },
    beforeChapter() { // 上一章
        let index = this.menuContext2.indexOf(this.index);
        if (index === 0) {
            this.obj = this.menuContext[this.menuContext2[index]]
            this.index = this.obj.id
            this.active(this.obj['index'])
        } else {
            this.obj = this.menuContext[this.menuContext2[index - 1]]
            this.index = this.obj.id
            this.active(this.obj['index'])
        }
    },
    afterChapter() { // 下一章
        let index = this.menuContext2.indexOf(this.index);
        if ((this.menuContext2.length - 1) === index) {
            this.obj = this.menuContext[this.menuContext2[index]]
            this.index = this.obj.id
            this.active(this.obj['index'])
        } else {
            this.obj = this.menuContext[this.menuContext2[index + 1]]
            this.index = this.obj.id
            this.active(this.obj['index'])
        }
    },
    getData(item) {
        this.obj = item.data;
        // this.path = [...item.path.split('>>>').filter(item => item !== '') ,item.data.title ];
        this.active(this.obj['index'])
        this.index = item.data.id;
    },
    dimensionality(item, array, index) {
        item.data['index'] = index;
        array.push(item.data);
        if (item.children && item.children.length) {
            let index1 = 0;
            for (let item2 of item.children) {
                this.dimensionality(item2, array, `${item.data['index']}-${index1}`)
                index1++;
            }
        }
        return;
    }
}
}
</script>

<style lang="less" scoped>
.container {
    .left {
        width: 250px;
        .t-menu {
            border: 1px solid #f5f5f5;
        }
    }
    .right {
        padding: 10px 20px;
        width: calc(100% - 250px);
        border-left: 1px solid #f5f5f5;
        .el-breadcrumb {
            height: 25px;
            line-height: 25px;
            margin-bottom: 5px;
        }

        .chapter-nav-bar {
            width: 100%;
            display: flex;
            align-items: center;
            border: 1px solid #f5f5f5;
            height: 30px;
            .left-btn {
                width: 25px;
                height: 30px;
                flex-shrink: 0;
                span {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
            .content {
                text-align: center;
                width: calc(100% - 40px);
                border-left: 1px solid #f5f5f5;
                border-right: 1px solid #f5f5f5;
            }
            .right-btn{
                width: 25px;
                height: 30px;
                flex-shrink: 0;
                span {
                    font-size: 25px;
                    line-height: 30px;
                }
            }
        }
        .html-content {
            padding: 10px;
            min-height: 500px;
        }
        .bottom-nav-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            .bottom {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 250px;
                height: 40px;
                background-color: #f9f9f9;
                user-select: none;
                .left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    height: 100%;
                    &:hover {
                        color: white;
                        background-color: #c7000b;
                    }
                }
                .right {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    height: 100%;
                    &:hover {
                        color: white;
                        background-color: #c7000b;
                    }
                }
            }
        }
    }
}
</style>